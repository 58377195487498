<template>
	<v-card v-bind="cardProps">
		<v-row dense>
			<v-col cols="12" md="6">
				<v-card class="pa-4 ma-4 elevation-3">
					<v-card-title>{{ $t("addTags") }}</v-card-title>
					<v-card-text>
						<form data-vv-scope="formAddTag">
							<v-row dense>
								<v-col>
									<VExtendedCombobox
										v-model="tags.model"
										:delay="300"
										:minChars="2"
										:enableCache="true"
										:asyncDataProvider="AutoCompeteQuery"
										:label="$t('tagName')"
										prepend-icon="fas fa-tag"
										return-object
										v-validate="'required'"
										:error-messages="errors.collect('formAddTag.tagname')"
										data-vv-scope="formAddTag"
										data-vv-name="tagname"
										:data-vv-as="$t('tagName')"
									></VExtendedCombobox>
								</v-col>
							</v-row>
						</form>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="AddTag" color="primary" :loading="addTagLoading">
							{{ $t("addTag") }}
							<v-icon right dark>fas fa-plus</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" md="6">
				<v-card class="pa-4 ma-4 elevation-3">
					<!-- <v-progress-circular :size="50" color="primary" v-if="this.tagsLoading" indeterminate></v-progress-circular> -->

					<div v-if="!this.tagsLoading">
						<v-alert
							:value="true"
							type="info"
							v-if="this.tags.ItemTags.length === 0"
							>{{ $t("noTags") }}</v-alert
						>
						<v-chip
							v-for="item in tags.ItemTags"
							:key="item.TagId"
							@click:close="removeTag(item.TagId)"
							close
							class="ml-3"
							label
							text-color="white"
							color="purple darken-3"
						>
							<v-icon left>label</v-icon>
							{{ item.TagName }}
						</v-chip>
					</div>
					<div v-if="this.tagsLoading">
						<v-chip v-for="n in 4" :key="n" class="shine ml-3" label>
							<v-icon left class="grey--text text--lighten-3">label</v-icon>
							<span class="px-2">
								<v-icon left class="grey--text text--lighten-3"
									>fas fa-ellipsis-h</v-icon
								>
							</span>
						</v-chip>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import xCombo from "@/components/Shared/FormControl/VExtendedCombobox";

export default {
	$_veeValidate: { validator: "newtag" },
	components: { VExtendedCombobox: xCombo },
	props: {
		cardProps: Object,
		ContentId: Number,
		Service: Object,
	},
	data: () => ({
		tagsLoading: false,
		addTagLoading: false,
		tags: {
			search: null,
			model: null,
			ItemTags: [],
		},
	}),
	watch: {
		ContentId: {
			immediate: true,
			async handler(newVal) {
				this.clearForm();
				if (newVal && newVal > 0) {
					await this.LoadContentTags();
				}
			},
		},
	},
	methods: {
		async AutoCompeteQuery(val) {
			return await this.Service.GetAutocomplete(this.ContentId, val);
		},

		// wrapped in setTimeout otherwise v-combobox does not update v-model before validation
		async AddTag() {
			setTimeout(async () => await this.AddTagInner());
		},

		async AddTagInner() {
			const res = await this.$validator.validateAll("formAddTag");
			if (!res) return;
			try {
				this.addTagLoading = true;
				await this.Service.AddTag(this.ContentId, this.tags.model);
				this.tags.model = "";
				this.tags.autocompleteItems = [];
				this.$validator.reset();
				await this.LoadContentTags();
			} catch (error) {
				// TODO: show toastr
			} finally {
				this.addTagLoading = false;
			}
		},

		async removeTag(tagId) {
			await this.Service.RemoveTag(this.ContentId, tagId);
			await this.LoadContentTags();
		},

		async LoadContentTags() {
			this.tagsLoading = true;
			try {
				const res = await this.Service.GetTags(this.ContentId);
				this.tags.ItemTags = res;
			} finally {
				this.tagsLoading = false;
			}
		},

		clearForm() {
			this.tags.model = null;
			this.tags.ItemTags = [];
		},
	},
	// TODO: clear comobobox cache everytime it's loaded and after add
	mounted: function () {
		this.$log.info("TagEditor Mounted");
	},
};
</script>

<i18n>
{
	"it": {
		"pageTitle": "Editor di tag",
		"addTag": "Aggiungi tag",
		"addTags": "Aggiungi tag",
		"noTags": "Non sono presenti tag per questo elemento",
		"tagName" : "Nome tag"
	},
	"en": {
		"noTags": "No tags for this item"
	}
}
</i18n>
