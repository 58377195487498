<template>
	<div>
		<confirm ref="confirm"></confirm>

		<v-toolbar dark color="primary" v-if="ShowToolbar">
			<v-btn icon dark @click="CloseMainDialog">
				<v-icon>close</v-icon>
			</v-btn>
			<v-toolbar-title
				>{{ $t("editGallery") }} - {{ GalleryName }}</v-toolbar-title
			>
			<v-spacer></v-spacer>
		</v-toolbar>

		<v-progress-linear
			:indeterminate="true"
			v-show="LoadingData"
		></v-progress-linear>

		<v-card flat>
			<v-card-text style="height: 0px; position: relative">
				<v-btn absolute @click="OpenNewDialog" dark fab top right color="green">
					<v-icon>add</v-icon>
				</v-btn>
			</v-card-text>

			<v-container fluid>
				<v-alert
					:value="!LoadingData && items.length === 0"
					class="pa-4 ma-4"
					type="info"
					>{{ $t("common.noItems") }}</v-alert
				>
				<!-- Instead of using v-row, wrap with draggable with class names  -->
				<draggable
					v-model="items"
					:tag="'div'"
					class="layout row wrap"
					@end="DragEnd"
					@start="DragStart"
					handle=".dragElement"
					v-bind="{ animation: 450 }"
				>
					<v-col
						cols="12"
						sm="6"
						md="4"
						lg="3"
						xl="2"
						class="pa-3"
						v-for="item in items"
						:key="item.GalleryImageId"
					>
						<v-card class="draggableCard elevateOnHover">
							<v-img
								:src="item.ImageFilePath[400]"
								v-if="item.ImageFilePath !== null"
								aspect-ratio="1.5"
								:contain="true"
								:alt="item.GalleryImageId + ''"
								@error="this.$log.info('img error')"
							></v-img>

							<!-- <LazyYoutubeVideo
								v-if="item.VideoId"
								:src="`https://www.youtube.com/embed/${item.VideoId}`"
								previewImageSize="hqdefault"
								:webp="false"
							>
								<template slot="button">
									<v-icon>fab fa-youtube</v-icon>
									<v-icon>fas fa-play</v-icon>
								</template>
							</LazyYoutubeVideo> -->

							<div style="height: 48px" class="pa-3 one-line">
								<span class="grey--text">{{ item.Caption }}</span>
							</div>

							<v-card-actions class="cardactions_Aligned">
								<v-icon class="dragElement">fas fa-arrows</v-icon>
								<v-spacer></v-spacer>

								<v-btn
									text
									icon
									color="primary"
									@click="OpenEditImageDialog(item.GalleryImageId)"
								>
									<v-icon>fas fa-edit</v-icon>
								</v-btn>

								<v-btn
									text
									icon
									color="error"
									@click="DeleteGalleryImage(item.GalleryImageId)"
								>
									<v-icon>fas fa-trash</v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</draggable>
			</v-container>
		</v-card>

		<v-dialog v-model="dialogData.ShowDialog" max-width="600px">
			<form
				data-vv-scope="formImageDetails"
				@keydown.esc="closeEditAuthorDialog"
				v-if="metadataLoaded"
			>
				<v-card class="white--text">
					<v-card-title class="secondary">
						<span class="headline">{{ dialogData.Title }}</span>
					</v-card-title>
					<v-card-text class="px-0">
						<v-container class="pa-0">
							<v-tabs color="white" dark slider-color="primary" v-model="activeTab">
								<v-tab
									ripple
									:key="0"
									:disabled="dialogData.Mode === 2 && dialogData.HasImage === false"
									>{{ $t("dialogData.image") }}</v-tab
								>
								<v-tab-item
									:disabled="dialogData.Mode === 2 && dialogData.HasImage === false"
								>
									<v-col cols="12">
										<input
											type="file"
											style="display: none"
											ref="image"
											accept="image/*"
											@change="onFilePicked"
										/>
										<v-img
											:src="dialogData.PreviewImage"
											class="imgPreview"
											width="300"
											v-if="!imageUrl && dialogData.HasImage"
											@click="pickFile"
										></v-img>

										<v-img :src="imageUrl" width="300" v-if="imageUrl"></v-img>

										<div
											class="NoImage text-center elevation-3"
											v-if="!imageUrl && !dialogData.HasImage"
											@click="pickFile"
										>
											<v-icon dark size="80">fas fa-fw fa-camera-alt</v-icon>
											<br />
											{{ $t("common.noImage") }}
										</div>
									</v-col>
								</v-tab-item>
								<v-tab
									ripple
									:key="1"
									:disabled="dialogData.Mode === 2 && dialogData.HasVideo === false"
									>{{ $t("dialogData.video") }}</v-tab
								>
								<v-tab-item
									:disabled="dialogData.Mode === 2 && dialogData.HasVideo === false"
								>
									<v-col cols="12">
										<v-text-field
											:label="$t('videoId')"
											:data-vv-as="$t('videoId')"
											data-vv-scope="formImageDetails"
											data-vv-name="VideoId"
											data-vv-delay="300"
											v-model="dialogData.VideoId"
											:error-messages="errors.collect('formImageDetails.VideoId')"
											:counter="metadata['VideoId'].MaxLength"
											v-validate="metadata['VideoId'].ClientValidators"
											@paste.stop.prevent="getVideoId"
										></v-text-field>
									</v-col>
								</v-tab-item>
							</v-tabs>

							<v-col cols="12">
								<v-text-field
									:label="$t('caption')"
									:data-vv-as="$t('caption')"
									data-vv-scope="formImageDetails"
									data-vv-name="Caption"
									data-vv-delay="300"
									:counter="metadata['Caption'].MaxLength"
									v-validate="metadata['Caption'].ClientValidators"
									v-model="dialogData.Caption"
									:error-messages="errors.collect('formImageDetails.Caption')"
								></v-text-field>
							</v-col>

							<template v-if="infoBlock">
								<v-col cols="12">
									<v-checkbox
										v-model="dialogData.ShowInfoBlock"
										:label="$t('showInfoBlock?')"
										type="checkbox"
									></v-checkbox>
								</v-col>

								<template v-if="dialogData.ShowInfoBlock">
									<v-col cols="12">
										<v-textarea
											:label="$t('htmlInfoBlock')"
											:data-vv-as="$t('htmlInfoBlock')"
											data-vv-scope="formImageDetails"
											data-vv-name="HtmlInfoBlock"
											data-vv-delay="300"
											:counter="metadata['HtmlInfoBlock'].MaxLength"
											v-validate="metadata['HtmlInfoBlock'].ClientValidators"
											v-model="dialogData.HtmlInfoBlock"
											:error-messages="errors.collect('formImageDetails.HtmlInfoBlock')"
										></v-textarea>
									</v-col>

									<v-col cols="12">
										<v-text-field
											:label="$t('infoBlockLink')"
											:data-vv-as="$t('infoBlockLink')"
											data-vv-scope="formImageDetails"
											data-vv-name="InfoBlockLink"
											data-vv-delay="300"
											:counter="metadata['InfoBlockLink'].MaxLength"
											v-validate="metadata['InfoBlockLink'].ClientValidators"
											v-model="dialogData.InfoBlockLink"
											:error-messages="errors.collect('formImageDetails.InfoBlockLink')"
										></v-text-field>
									</v-col>
								</template>
							</template>

							<v-col cols="12">
								<v-checkbox
									v-model="dialogData.IsActive"
									:label="$t('common.visible')"
									type="checkbox"
								></v-checkbox>
							</v-col>
						</v-container>
					</v-card-text>

					<CardActionAddSave
						@closeClicked="closeEditAuthorDialog"
						@addOrSaveClicked="AddOrSave"
						:mode="dialogData.Mode"
						:showCancelButton="true"
						:loading="dialogData.AddOrSaveLoading"
						:disabled="!(imageFile || dialogData.HasImage) && !dialogData.VideoId"
					></CardActionAddSave>
				</v-card>
			</form>
		</v-dialog>
	</div>
</template>

<script>
import draggable from "vuedraggable";
// import LazyYoutubeVideo from "vue-lazy-youtube-video";
import { mapActions } from "vuex";
import CardActionAddSave from "@/components/Shared/FormControl/CardActionAddSave";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import metadata from "@/mixins/metadata";
import CrudClient from "@/services/CrudClient/";
import Helpers from "@/utilities/Helpers/";

export default {
	name: "GalleryImages",
	$_veeValidate: { validator: "galleryImages" },
	components: {
		confirm: ConfirmTool,
		draggable,
		// LazyYoutubeVideo,
		CardActionAddSave,
	},
	props: {
		GalleryId: Number,
		GalleryName: String,
		GalleryImagesService: {
			type: Object,
			default: () => new CrudClient("GalleryImages"),
		},
		infoBlock: {
			type: Boolean,
			default: false,
		},
		ShowToolbar: Boolean,
	},
	data: () => ({
		LoadingData: false,
		items: [],
		itemsPaging: [], // GalleryId, GalleryName
		currentPage: 1,
		pageSize: 10,
		dialogData: {
			GalleryImageId: null,
			IsActive: true,
			Caption: "",
			ShowInfoBlock: false,
			HtmlInfoBlock: null,
			InfoBlockLink: null,
			VideoId: null,
			VideoProviderId: 1, // for now always youtube
			editDialogLoadingData: false,
			AddOrSaveLoading: false,
			ShowDialog: false,
			Mode: 1, // 1 - New, 2 - Edit
			Title: "",
			HasImage: false,
			HasVideo: false,
			PreviewImage: "",
			DragItemsList: [],
		},
		activeTab: 0,
		imageName: "",
		imageUrl: "",
		imageFile: "",
	}),
	mixins: [metadata],
	watch: {
		GalleryId: {
			immediate: true,
			async handler(newVal) {
				// reset images list
				this.items = [];
				this.itemsPaging = []; // GalleryId, GalleryName
				this.currentPage = 1;
				if (newVal && newVal > 0) {
					await this.LoadGalleryImages();
				}
			},
		},
	},
	methods: {
		...mapActions(["snackSuccess", "snackError", "snackMetadataError"]),

		getVideoId(e) {
			let url = (e.clipboardData || window.clipboardData).getData("text");
			let id = "";
			url = url
				.replace(/(>|<)/gi, "")
				.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
			if (url[2] !== undefined) {
				id = url[2].split(/[^0-9a-z_-]/i);
				id = id[0];
			} else {
				id = url[0];
			}
			this.dialogData.VideoId = id;
		},

		async LoadGalleryImages() {
			try {
				this.LoadingData = true;
				const res = await this.GalleryImagesService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "GalleryImageId, ImageFilePath, VideoId, Caption",
					filter: "GalleryId:" + this.GalleryId,
					orderBy: "SortOrder",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadGallery") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		CloseMainDialog() {
			this.$emit("close-dialog");
			this.$log.info("close-dialog" + " emitted");
		},

		closeEditAuthorDialog() {
			this.$log.info("Close main dialog");
			this.dialogData.ShowDialog = false;
		},

		OpenNewDialog() {
			this.$log.info("OpenNewDialog");
			this.ClearForm();
			this.dialogData.Mode = 1;
			this.dialogData.Title = this.$t("addImages");
			this.dialogData.ShowDialog = true;
		},

		async OpenEditImageDialog(galleryImageId) {
			this.$log.info("OpenEditImageDialog");
			// this.ClearForm();
			this.dialogData.Mode = 2;
			this.dialogData.Title = this.$t("editImage");
			this.dialogData.GalleryImageId = galleryImageId;
			await this.LoadContentDetails();

			this.activeTab = this.dialogData.HasImage ? 0 : 1;

			this.dialogData.ShowDialog = true;
		},

		DragStart() {
			this.DragItemsList = this.items.map((item) => item.GalleryImageId);
		},

		async DragEnd() {
			// BUG: resizing with paging doesn't work as expected

			// this.$log.info("Drag End");
			const itemsArray = this.items.map((item) => item.GalleryImageId);

			// compare to initial state
			if (Helpers.compareArrays(this.DragItemsList, itemsArray)) {
				return;
			}

			try {
				this.LoadingData = true;
				await this.GalleryImagesService.Reorder(itemsArray, this.GalleryId);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
			} finally {
				this.LoadingData = false;
			}
		},

		pickFile() {
			this.$refs.image.click();
		},

		onFilePicked(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				this.imageName = files[0].name;
				if (this.imageName.lastIndexOf(".") <= 0) {
					return;
				}

				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.imageUrl = fr.result;
					this.imageFile = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.imageName = "";
				this.imageFile = "";
				this.imageUrl = "";
			}
		},

		ClearImageUpload() {
			if (this.$refs.image) {
				this.$refs.image.value = "";
			}

			this.imageName = "";
			this.imageFile = "";
			this.imageUrl = "";
		},

		ClearForm() {
			Object.keys(this.dialogData).map((e) => {
				this.dialogData[e] = null;
			});

			// non null defaults
			this.dialogData.IsActive = true;
			this.dialogData.ShowInfoBlock = false;
			this.dialogData.HasImage = false;
			this.dialogData.PreviewImage = "";
			this.activeTab = 0;
			this.$validator.reset();

			this.ClearImageUpload();
		},

		async UpdateItemImage(item) {
			if (this.imageFile !== "") {
				const updatedImage = await this.GalleryImagesService.UpdateImage(
					item.GalleryImageId,
					this.imageName,
					this.imageFile
				);
				this.ClearImageUpload();
				this.dialogData.HasImage = updatedImage.ImageFilePath !== null;
				this.dialogData.PreviewImage =
					updatedImage.ImageFilePath !== null
						? updatedImage.ImageFilePath[400]
						: null;
			}
		},

		async AddOrSave() {
			const validationResult = await this.$validator.validateAll(
				"formImageDetails"
			);
			if (!validationResult) return;

			this.dialogData.AddOrSaveLoading = true;
			this.dialogData.VideoProviderId = 1;

			try {
				// add or update
				const newItem = {};
				newItem.GalleryId = this.GalleryId;
				const CopyItems = [
					"IsActive",
					"Caption",
					"ShowInfoBlock",
					"HtmlInfoBlock",
					"InfoBlockLink",
					"VideoId",
					"VideoProviderId",
				];
				CopyItems.map((v) => {
					newItem[v] = this.dialogData[v];
				});

				let res;
				if (this.dialogData.Mode === 1) {
					res = await this.GalleryImagesService.Add(newItem);
					await this.UpdateItemImage(res);
					this.snackSuccess({ Text: this.$t("common.success.uploadImage") });
				} else {
					res = await this.GalleryImagesService.Patch(
						this.dialogData.GalleryImageId,
						newItem
					);
					await this.UpdateItemImage(res);
					this.snackSuccess({ Text: this.$t("common.success.updateImage") });
				}
				this.dialogData.ShowDialog = false;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotSaveData") });
			} finally {
				this.dialogData.AddOrSaveLoading = false;
			}
			await this.LoadGalleryImages();
		},

		async LoadContentDetails() {
			this.dialogData.editDialogLoadingData = true;
			try {
				const res = await this.GalleryImagesService.Get(
					this.dialogData.GalleryImageId
				);
				this.dialogData.GalleryImageId = res.GalleryImageId;
				const CopyItems = [
					"IsActive",
					"Caption",
					"ShowInfoBlock",
					"HtmlInfoBlock",
					"InfoBlockLink",
					"VideoId",
					"VideoProviderId",
				];
				CopyItems.map((v) => {
					this.dialogData[v] = res[v];
				});
				this.dialogData.HasImage = res.ImageFilePath !== null;
				this.dialogData.PreviewImage =
					res.ImageFilePath !== null ? res.ImageFilePath[400] : null;
				this.dialogData.HasVideo = !!res.VideoId;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
			} finally {
				this.dialogData.editDialogLoadingData = false;
			}
		},

		async DeleteGalleryImage(galleryImageId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;

			try {
				await this.GalleryImagesService.Delete(galleryImageId);
				this.snackSuccess({ Text: this.$t("common.item.deleted") });
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotDeleteContent") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
			await this.LoadGalleryImages();
		},
	},
	async mounted() {
		this.$log.info("GalleryImagesView Mounted");
		await this.LoadMetadata(this.GalleryImagesService);
	},
};
</script>

<style lang="scss" scoped>
.draggableCard {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.one-line {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>

<style lang="scss">
@import "@/assets/css/variables.scss";

.y-video {
	border: red;
	height: calc(100% - 100px);
	background: black;
	&__inner {
		height: 0;
		position: relative;
		.v-icon {
			position: absolute;
			cursor: pointer;
			&.fa-youtube {
				left: calc(50% - 50px);
				top: calc(50% - 50px);
				font-size: 100px !important;
				color: $youTubePlay !important;
			}
			&.fa-play {
				position: absolute;
				left: calc(50% - 6px);
				top: calc(50% - 18px);
				font-size: 33px !important;
				color: white !important;
				cursor: pointer;
			}
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__media--type--img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.v-dialog {
	.v-tab--disabled {
		display: none;
	}
}
</style>

<i18n>
{
	"it": {
		"caption": "Didascalia",
		"addImages": "Aggiungi immagine o video",
		"editImage": "Modifica immagine o video",
		"editGallery": "Modifica gallery",
		"htmlInfoBlock": "HtmlInfoBlock",
		"infoBlockLink": "InfoBlock Link",
		"showInfoBlock": "Mostra InfoBlock?",
		"videoId": "Youtube Video Id",
		"dialogData": {
			"image": "Immagine",
			"video": "Video"
		}
	}
}
</i18n>
