<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<v-card class="elevation-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
			</v-toolbar>
			<confirm ref="confirm"></confirm>

			<v-card tile>
				<v-card-text style="height: 0; position: relative">
					<v-btn absolute @click="OpenNewDialog" dark fab top right color="green">
						<v-icon>add</v-icon>
					</v-btn>
				</v-card-text>

				<skeleton-table v-if="LoadingData" :actions="2"></skeleton-table>

				<v-list v-else>
					<v-list-item v-for="item in items" :key="item.ContentId" class="elevateOnHover">
						<v-list-item-content>
							<v-list-item-title v-text="item.Title"></v-list-item-title>
						</v-list-item-content>

						<v-list-item-action>
							<v-btn icon ripple @click="OpenEditDialog(item.ContentId)">
								<v-icon color="primary">fas fa-fw fa-edit</v-icon>
							</v-btn>
						</v-list-item-action>

						<v-list-item-action class="ml-0">
							<v-btn icon ripple @click="deleteStaticContent(item.ContentId)">
								<v-icon color="error">fas fa-fw fa-trash-alt</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
				</v-list>

				<v-alert :value="!LoadingData && items.length === 0" class="pa-4 ma-4" type="info">{{
					$t("common.noItems") }}</v-alert>

				<div class="text-center">
					<v-pagination v-model="currentPage" :length="itemsPaging.TotalPages"
						:total-visible="5"></v-pagination>
				</div>
			</v-card>
		</v-card>

		<v-dialog v-model="dialogData.ShowDialog" persistent fullscreen hide-overlay no-click-animation
			@keydown.esc="CloseDialog">
			<v-overlay :value="dialogData.itemDetailsLoading">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-card tile>
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>
						{{ dialogData.Title }}
						<span v-if="dialogData.contentId">&mdash; Id: {{ dialogData.contentId }}</span>
					</v-toolbar-title>
				</v-toolbar>
				<div class="tabs-container">
					<v-tabs v-model="dialogData.activeTab" color="cyan" dark align-with-title slider-color="yellow">
						<v-tab ripple>{{ $t("common.info") }}</v-tab>
						<v-tab-item>
							<SimpleFormCard :cardProps="{ flat: true, class: 'pa-4 elevation-3' }"
								ref="formAddStaticContent" :formContent="formAddStaticContent"
								:initialValues="dialogData.itemDetails" :metadata="metadata"
								:metadataLoaded="metadataLoaded" :mode="dialogData.Mode" @submitForm="AddOrSave"
								:showCancelButton="false" :locali18n="$i18n"></SimpleFormCard>
						</v-tab-item>

						<v-tab ripple v-if="this.dialogData.Mode === 2">{{
							$t("common.content")
							}}</v-tab>
						<v-tab-item v-if="this.dialogData.Mode === 2">
							<v-overlay absolute v-show="formEditor.SendingData">
								<v-progress-circular indeterminate size="64"></v-progress-circular>
							</v-overlay>
							<VueEditor v-model="formEditor.Text" id="editor" useCustomImageHandler
								@image-added="handleImageAdded"></VueEditor>

							<v-divider mx-3></v-divider>
							<v-btn @click="UpdateContent" color="primary" large><v-icon left>fas fa-save</v-icon>{{
								$t("common.save") }}</v-btn>
						</v-tab-item>

						<v-tab ripple v-if="this.dialogData.Mode === 2">{{
							$t("common.coverImage")
							}}</v-tab>
						<v-tab-item v-if="this.dialogData.Mode === 2">
							<v-col cols="12">
								<image-upload ref="imageUpload" :Service="StaticContentService"
									:ContentId="dialogData.contentId" :HasImage="editAuthorForm.HasImage"
									:PreviewImage="editAuthorForm.PreviewImage" @imageUpdated="OnImageUpdated"
									@imageUpdateFailed="OnImageUpdateFailed" @imageDeleted="OnImageUpdated"
									@imageDeleteFailed="OnImageDeleteFailed"></image-upload>
							</v-col>
							<SimpleFormCard :cardProps="{ flat: true, class: 'pa-4' }" ref="formImageCaption"
								:formContent="formImageCaption" :initialValues="dialogData.itemDetails"
								:metadata="metadata" :metadataLoaded="metadataLoaded" :mode="dialogData.Mode"
								@submitForm="UpdateImageCaption" :showCancelButton="false" :locali18n="$i18n">
							</SimpleFormCard>
						</v-tab-item>

						<v-tab ripple v-if="this.dialogData.Mode === 2">{{ $t("tags") }}</v-tab>
						<v-tab-item v-if="this.dialogData.Mode === 2">
							<TagEditor :cardProps="{ class: 'pa-4' }" :Service="StaticContentService"
								:ContentId="dialogData.contentId"></TagEditor>
						</v-tab-item>
					</v-tabs>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import ImageUpload from "./components/ImageUpload";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import TagEditor from "./components/TagEditor";
import metadata from "@/mixins/metadata";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";

// Advanced Use import { VueEditor, Quill } - Hook into Quill's API for Custom Functionality
import { VueEditor } from "vue2-editor";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	name: "StaticContent",
	$_veeValidate: { validator: "newStatic" },
	components: {
		confirm: ConfirmTool,
		ImageUpload,
		SimpleFormCard,
		TagEditor,
		VueEditor
	},
	mixins: [metadata],
	data() {
		return {
			LoadingData: false,
			items: [],
			itemsPaging: [], // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 10,
			dialogData: {
				ShowDialog: false,
				Mode: 1, // 1 - New, 2 - Edit
				Title: "",
				activeTab: 0,
				contentId: null,
				AddOrSaveLoading: false,
				itemDetailsLoading: false,
				itemDetails: null,
				GalleryList: [],
			},
			editAuthorForm: {
				HasImage: false,
				PreviewImage: "",
			},
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				// {
				// 	text: "CMS",
				// 	disabled: false,
				// 	exact: true,
				// 	to: "/Admin/Content",
				// },
			],
			formAddStaticContent: new CrudFormData("formAddStaticContent", [
				"Title",
				"FriendlyUrl",
				{
					type: "v-textarea",
					FieldName: "MetaDescription",
					rows: 1,
					"auto-grow": true,
				},
				"GalleryId",
				{
					type: "v-select",
					FieldName: "GalleryId",
					"item-text": "GalleryName",
					"item-value": "GalleryId",
				},
				{
					FieldName: "CreatePage",
					type: "v-checkbox",
				},
			]),

			formEditor: {
				SendingData: false,
				Text: null
			},
			formImageCaption: new CrudFormData("formImageCaption", ["ImageCaption"]),
		};
	},
	watch: {
		"dialogData.ShowDialog": function (val) {
			!val && this.clearDialogForm();
		},
		async currentPage() {
			await this.LoadStaticContentList();
		},
	},
	created() {
		this.StaticContentService = new CrudClient("StaticContent");
		this.GalleriesService = new CrudClient("Galleries");
	},
	async mounted() {
		this.$log.info("Static Content View Mounted");
		this.breadcrumbsItems.push(
			{
				text: this.$t("content"),
				disabled: false,
				exact: true,
				to: "/Admin/Content",
			},
			{
				text: this.$t("pageTitle"),
				disabled: true,
				exact: true,
				to: "/Admin/Content/StaticContent",
			}
		);
		await this.LoadStaticContentList();
		await this.LoadMetadata(this.StaticContentService);
		await this.LoadGalleryList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadStaticContentList() {
			this.LoadingData = true;
			try {
				const res = await this.StaticContentService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "ContentId,Title",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async deleteStaticContent(contentId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.StaticContentService.Delete(contentId);
				this.snackSuccess({ Text: this.$t("staticContentDeleted") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteContent") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadStaticContentList();
		},

		async UpdateImageCaption(value) {
			try {
				this.formImageCaption.FormSendingData = true;
				await this.StaticContentService.Patch(
					this.dialogData.contentId,
					value.ImageCaption
				);
				this.snackSuccess({ Text: this.$t("updatedImageCaption") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formImageCaption.FormSendingData = false;
			}
			await this.LoadContentDetails(); // refresh details
		},

		OnImageUpdated(res, suppressEmit) {
			this.editAuthorForm.HasImage = res.ImageFilePath !== null;
			this.editAuthorForm.PreviewImage =
				res.ImageFilePath !== null ? res.ImageFilePath[400] : null;
			if (!suppressEmit) {
				this.snackSuccess({ Text: this.$t("common.success.updateImage") });
			}
		},

		OnImageUpdateFailed() {
			this.snackError({ Text: this.$t("common.error.cannotUpdateImage") });
		},

		OnImageDeleteFailed() {
			this.snackError({ Text: this.$t("common.error.cannotDeleteImage") });
		},

		async LoadGalleryList() {
			const res = await this.GalleriesService.GetPaged({
				fields: "GalleryId, GalleryName",
			});
			res.Data.unshift({ GalleryId: null, GalleryName: "--" });
			this.formAddStaticContent.Fields.find(
				(f) => f.FieldName === "GalleryId"
			).items = res.Data;
		},

		async UpdateContent() {
			try {
				this.formEditor.SendingData = true;

				const dto = { Text: this.formEditor.Text };
				await this.StaticContentService.Patch(this.dialogData.contentId, dto);
				this.snackSuccess({ Text: this.$t("common.success.updateContent") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formEditor.SendingData = false;
			}
			await this.LoadContentDetails(); // refresh details
		},

		async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
			this.$log.debug("handleImageAdded");
			const formData = new FormData();
			formData.append("image", file);

			const updatedData = await this.StaticContentService.UpdateImage(
				this.dialogData.contentId,
				file.name,
				file,
				"inlineImage"
			);

			const url = updatedData.Uri; // Get url from response
			Editor.insertEmbed(cursorLocation, "image", url);
			resetUploader();
		},

		async OpenNewDialog() {
			this.dialogData.Mode = 1;
			this.dialogData.Title = this.$t("newStaticContent");
			this.dialogData.ShowDialog = true;
			this.dialogData.activeTab = 0;
			await this.LoadGalleryList();
		},

		async OpenEditDialog(contentId) {
			this.dialogData.Mode = 2;
			this.dialogData.Title = this.$t("editStaticContent");
			this.dialogData.ShowDialog = true;
			this.dialogData.activeTab = 0;
			this.dialogData.contentId = contentId;
			await this.LoadContentDetails();
		},

		async LoadContentDetails() {
			try {
				this.dialogData.itemDetailsLoading = true;
				const res = await this.StaticContentService.Get(this.dialogData.contentId);
				this.dialogData.itemDetails = res;
				this.formEditor.Text = res.Text;

				this.OnImageUpdated(res, true);
			} finally {
				this.dialogData.itemDetailsLoading = false;
			}
		},

		async AddOrSave(value) {
			this.formAddStaticContent.FormSendingData = true;
			try {
				if (this.dialogData.Mode === 1) {
					const res = await this.StaticContentService.Add(value);
					this.snackSuccess({ Text: this.$t("common.item.created") });
					await this.OpenEditDialog(res.ContentId);
				} else {
					const res = await this.StaticContentService.Patch(
						this.dialogData.contentId,
						value
					);
					this.snackSuccess({ Text: this.$t("common.item.updated") });
					await this.OpenEditDialog(res.ContentId);
				}
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdate") });
			} finally {
				this.formAddStaticContent.FormSendingData = false;
			}
			await this.LoadStaticContentList();
		},

		CloseDialog() {
			this.dialogData.ShowDialog = false;
		},

		clearDialogForm() {
			this.$log.info("Clear form data");
			if (this.$refs.formAddStaticContent) {
				this.$refs.formAddStaticContent.ResetForm();
			}
			if (this.$refs.formImageCaption) {
				this.$refs.formImageCaption.ResetForm();
			}
			// if (this.$refs.formEditor) {
			// 	this.$refs.formEditor.ResetForm();
			// }
			if (this.$refs.imageUpload) {
				this.$refs.imageUpload.clearImageUpload();
			}

			this.$validator.reset();
		},
	},
};
</script>

<style lang="scss">
.quill-editor {
	.ql-container {
		&.ql-snow {
			min-height: 250px;
		}
	}
}
.tabs-container {
	.v-tabs-items {
		overflow-y: auto;
		max-height: calc(100vh - 112px);
	}
}
</style>

<i18n>
{
	"it": {
		"pageTitle": "Contenuti statici",
		"content": "Content Home Page",
		"staticContentDeleted": "Contenuto statico eliminato con successo",
		"newStaticContent": "Nuovo contenuto statico",
		"editStaticContent": "Modifica contenuto statico",
		"tags": "tag",
		"formAddStaticContent": {
			"Title": "Titolo",
			"FriendlyUrl": "FriendlyUrl (optional)",
			"MetaDescription": "Descrizione per i motori di ricerca",
			"GalleryId": "Gallery (optional)",
			"ImageCaption": "Didascalia",
			"CreatePage": "Crea pagina?"
		},
		"formEditor": {
			"Text": "Contenuto"
		},
		"formImageCaption": {
			"ImageCaption": "Didascalia"
		}
	}
}
</i18n>
