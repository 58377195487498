<template>
	<div class="pa-4">
		<confirm ref="confirm"></confirm>
		<input type="file" style="display:none" ref="image" accept="image/*" @change="onFilePicked" />

		<v-img :src="imageUrl" :width="ImageWidth" v-if="imageUrl"></v-img>
		<v-img
			:src="PreviewImage"
			class="imgPreview"
			:width="ImageWidth"
			v-else-if="HasImage"
			@click="pickFile"
		></v-img>
		<div class="NoImage text-center elevation-3" v-else @click="pickFile">
			<v-icon dark size="80">fas fa-fw fa-camera-alt</v-icon>
			<br />
			{{$t('common.noImage')}}
		</div>

		<div class="mt-4">
			<v-btn color="primary" small v-if="imageUrl" @click="updateImage()" :loading="UploadingImage">
				{{$t('common.save')}}
				<v-icon right dark small>fas fa-cloud-upload</v-icon>
			</v-btn>
			<v-btn text color="primary" small @click="clearImageUpload()" v-if="imageUrl && !UploadingImage">
				{{$t('common.cancel')}}
				<v-icon right dark small>fas fa-fw fa-times</v-icon>
			</v-btn>
			<v-btn
				color="error"
				small
				v-if="!imageUrl && HasImage"
				@click="deleteImage()"
				:loading="DeletingImage"
			>
				{{$t('common.delete')}}
				<v-icon right dark small>fas fa-trash</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
import ConfirmTool from "@/components/Shared/Common/Confirm";

export default {
	name: "ImageUpload",
	components: { confirm: ConfirmTool },
	props: {
		ContentId: Number,
		HasImage: Boolean,
		ImageWidth: {
			type: Number,
			default: 400
		},
		PreviewImage: String,
		Service: Object
	},
	data() {
		return {
			imageName: "",
			imageUrl: "",
			imageFile: "",
			DeletingImage: false,
			UploadingImage: false
		};
	},
	methods: {
		pickFile() {
			this.$refs.image.click();
		},

		onFilePicked(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				this.imageName = files[0].name;
				if (this.imageName.lastIndexOf(".") <= 0) { return; }

				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.imageUrl = fr.result;
					this.imageFile = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.imageName = "";
				this.imageFile = "";
				this.imageUrl = "";
			}
		},

		clearImageUpload() {
			this.imageName = "";
			this.imageFile = "";
			this.imageUrl = "";
		},

		async updateImage() {
			this.UploadingImage = true;
			try {
				this.$log.info(this.ContentId);
				const updatedData = await this.Service.UpdateImage(
					this.ContentId,
					this.imageName,
					this.imageFile
				);
				this.clearImageUpload();
				this.$emit("imageUpdated", updatedData);
			} catch (error) {
				this.$log.info(error);
				this.$emit("imageUpdateFailed", error);
			} finally {
				this.UploadingImage = false;
			}
		},

		async deleteImage() {
			if (!(await this.$refs.confirm.open(
				this.$t("common.deleteImage"),
				this.$t("common.confirmDelete"),
				{ color: "error" }
			))) { return; }

			try {
				this.DeletingImage = true;
				const updatedData = await this.Service.DeleteImage(
					this.ContentId,
					this.imageName,
					this.imageFile
				);
				this.$emit("imageDeleted", updatedData);
			} catch (error) {
				this.$emit("imageDeleteFailed", error);
			} finally {
				this.DeletingImage = false;
			}
		}
	}
};
</script>
