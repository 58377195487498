<template>
	<div>
		<confirm ref="confirm"></confirm>

		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<v-card class="elevation-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
			</v-toolbar>

			<v-card-text style="height: 0px; position: relative">
				<v-btn absolute @click="OpenNewDialog" dark fab top right color="green">
					<v-icon>add</v-icon>
				</v-btn>
			</v-card-text>

			<skeleton-table v-if="LoadingData" :actions="3"></skeleton-table>

			<v-list v-else>
				<v-list-item
					v-for="item in items"
					:key="item.GalleryId"
					class="elevateOnHover"
				>
					<v-list-item-content>
						<v-list-item-title v-text="item.GalleryName"></v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<v-btn icon ripple @click="OpenGallery(item)">
							<v-icon color="primary">fas fa-fw fa-images</v-icon>
						</v-btn>
					</v-list-item-action>

					<v-list-item-action>
						<v-btn icon ripple @click="OpenEditDialog(item.GalleryId)">
							<v-icon color="primary">fas fa-fw fa-edit</v-icon>
						</v-btn>
					</v-list-item-action>
					<v-list-item-action class="ml-0">
						<v-btn icon ripple @click="DeleteGallery(item.GalleryId)">
							<v-icon color="error">fas fa-fw fa-trash-alt</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>

			<v-alert
				:value="!LoadingData && items.length === 0"
				class="pa-4 ma-4"
				type="info"
				>{{ $t("common.noItems") }}</v-alert
			>

			<div class="text-center">
				<v-pagination
					v-model="currentPage"
					:length="itemsPaging.TotalPages"
					:total-visible="5"
				></v-pagination>
			</div>
		</v-card>
		<v-dialog v-model="dialogData.ShowDialog" max-width="500px">
			<SimpleFormCard
				:mode="dialogData.Mode"
				ref="formAddGallery"
				:formContent="formAddGallery"
				:metadata="metadata"
				:metadataLoaded="metadataLoaded"
				@submitForm="AddOrSave"
				@closeClicked="CloseDialog"
				:showCancelButton="true"
				:locali18n="$i18n"
				:addButtonProps="{ color: 'primary' }"
			>
				<template slot="card-title">
					<div class="primary white--text px-5 py-3">
						<v-btn icon dark @click="CloseDialog">
							<v-icon>close</v-icon>
						</v-btn>
						<span class="headline">{{ dialogData.Title }}</span>
					</div>
				</template>
			</SimpleFormCard>
		</v-dialog>
		<v-dialog
			v-model="ShowGalleryImagesDialog"
			persistent
			no-click-animation
			@keydown.esc="CloseDialog"
		>
			<v-card>
				<gallery-images
					:GalleryId="SelectedGalleryId"
					:GalleryName="SelectedGalleryName"
					:ShowToolbar="true"
					infoBlock
					v-on:close-dialog="ShowGalleryImagesDialog = false"
				></gallery-images>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import GalleryImages from "./components/Images";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import metadata from "@/mixins/metadata";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},
	name: "Galleries",
	$_veeValidate: { validator: "newGallery" },
	components: {
		confirm: ConfirmTool,
		GalleryImages,
		SimpleFormCard,
	},
	mixins: [metadata],
	data() {
		return {
			GalleriesService: new CrudClient("Galleries"),
			LoadingData: false,
			items: [],
			itemsPaging: [], // GalleryId, GalleryName
			currentPage: 1,
			pageSize: 10,
			dialogData: {
				ShowDialog: false,
				Mode: 1, // 1 - New, 2 - Edit
				Title: "",
				GalleryId: null,
				GalleryName: "",
				itemDetailsLoading: false,
			},
			formAddGallery: new CrudFormData("formAddGallery", ["GalleryName"]),
			ShowGalleryImagesDialog: false,
			SelectedGalleryId: null,
			SelectedGalleryName: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	watch: {
		async currentPage() {
			await this.LoadGalleriesList();
		},
	},
	async mounted() {
		this.$log.info("Galleries View Mounted");
		this.breadcrumbsItems.push(
			{
				text: this.$t("content"),
				disabled: false,
				exact: true,
				to: "/Content",
			},
			{
				text: this.$t("pageTitle"),
				disabled: true,
				exact: true,
				to: "/Content/Galleries",
			}
		);
		await this.LoadMetadata(this.GalleriesService);
		await this.LoadGalleriesList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadGalleriesList() {
			try {
				this.LoadingData = true;
				const res = await this.GalleriesService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "GalleryId,GalleryName",
					filter: "OwnerUserId:isnull",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.snackError({ Text: this.$t("Cannot load galleries") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		CloseDialog() {
			this.dialogData.ShowDialog = false;
		},

		OpenGallery(Gallery) {
			this.ShowGalleryImagesDialog = true;
			this.SelectedGalleryId = Gallery.GalleryId;
			this.SelectedGalleryName = Gallery.GalleryName;
		},

		async DeleteGallery(contentId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.GalleriesService.Delete(contentId);
				this.snackSuccess({ Text: this.$t("common.item.deleted") });
			} catch (error) {
				this.showSnackbar = true;
				this.snackError({ Text: this.$t("common.error.cannotDeleteContent") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
			await this.LoadGalleriesList();
		},

		ClearForm() {
			this.dialogData.GalleryName = "";
			this.$validator.reset();
		},

		async OpenNewDialog() {
			this.ClearForm();
			this.dialogData.Mode = 1;
			this.dialogData.Title = this.$t("newGallery");
			this.dialogData.ShowDialog = true;
		},

		async OpenEditDialog(galleryId) {
			this.ClearForm();
			this.dialogData.Mode = 2;
			this.dialogData.Title = this.$t("editGallery");
			this.dialogData.ShowDialog = true;
			this.dialogData.GalleryId = galleryId;
			await this.LoadContentDetails();
		},

		async LoadContentDetails() {
			try {
				this.dialogData.itemDetailsLoading = true;
				const res = await this.GalleriesService.Get(this.dialogData.GalleryId);
				this.dialogData.GalleryName = res.GalleryName;
				this.dialogData.GalleryId = res.GalleryId;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
			} finally {
				this.dialogData.itemDetailsLoading = false;
			}
		},

		async AddOrSave(gallery) {
			this.formAddGallery.FormLoading = true;
			try {
				// add or update
				const newItem = {};
				newItem.GalleryName = gallery.GalleryName;

				if (this.dialogData.Mode === 1) {
					await this.GalleriesService.Add(newItem);
					this.snackSuccess({ Text: this.$t("galleryCreated") });
				} else {
					await this.GalleriesService.Patch(this.dialogData.GalleryId, newItem);
					this.snackSuccess({ Text: this.$t("galleryUpdated") });
				}
				this.dialogData.ShowDialog = false;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
			} finally {
				this.formAddGallery.FormLoading = false;
			}
			await this.LoadGalleriesList();
		},
	},
};
</script>

<i18n>
{
	"it": {
		"pageTitle": "Gallery",
		"content": "Content Home Page",
		"galleryCreated": "Gallery creata",
		"galleryUpdated": "Gallery aggiornata",
		"newGallery": "Nuova gallery",
		"editGallery": "Modifica gallery",
		"formAddGallery": {
			"GalleryName": "Nome della gallery"
		}
	}
}
</i18n>
