<template>
	<v-combobox
		:value="value"
		:items="autocompleteItems"
		:loading="autocompleteLoading"
		:search-input.sync="search"
		v-on="listeners"
		v-bind="attrs"
		@input="inputNative"
		@change="change"
	></v-combobox>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		value: String,
		asyncDataProvider: {
			type: Function,
		},
		delay: {
			type: Number,
			default: 250,
		},
		enableCache: {
			type: Boolean,
			default: false,
		},
		minChars: {
			type: Number,
			default: 2,
		},
	},
	data: () => ({
		autocompleteItems: [],
		autocompleteLoading: false,
		autocompleteTimeoutId: null,
		cachedResults: {},
		lastSelectedValue: null,
		search: null,
	}),
	computed: {
		listeners() {
			/* eslint-disable-next-line no-unused-vars */
			const { input: unusedVar, ...listeners } = this.$listeners;
			return listeners;
		},
		attrs() {
			const { ...attrs } = this.$attrs;
			return attrs;
		},
	},
	watch: {
		search: async function (val) {
			if (this.autocompleteTimeoutId !== null) {
				clearTimeout(this.autocompleteTimeoutId);
			}
			if (val && val.length >= this.minChars) {
				// do not trigger autocomplete for value selected from list
				if (this.lastSelectedValue && this.lastSelectedValue === val) {
					return;
				}
				if (this.enableCache) {
					if (this.cachedResults[val]) {
						return this.cachedResults[val];
					}
				}
				this.autocompleteTimeoutId = setTimeout(async () => {
					try {
						this.autocompleteLoading = true;
						if (this.asyncDataProvider) {
							this.autocompleteItems = await this.asyncDataProvider(val);
						}
						if (this.enableCache) this.cachedResults[val] = this.autocompleteItems;
					} finally {
						this.autocompleteTimeoutId = null;
						this.autocompleteLoading = false;
					}
				}, this.delay);
			}
		},
	},
	methods: {
		change(value) {
			this.$emit("input", value);
			this.lastSelectedValue = value;
		},

		inputNative(event) {
			this.$emit("input", event.target.value);
		},
	},
};
</script>
